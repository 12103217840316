import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import FormStyle from './../../Element/FormStyle';


import team1 from './../../../images/our-team/pic1.jpg';
import team2 from './../../../images/our-team/pic2.jpg';
import team3 from './../../../images/our-team/pic3.jpg';
import team4 from './../../../images/our-team/pic4.jpg';
import team5 from './../../../images/our-team/av1.png';
import team6 from './../../../images/our-team/av1.png';


//Images
import  about9 from './../../../images/about/pic9.jpg';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
import bg1 from './../../../images/background/bg1.jpg';

import bnr from './../../../images/banner/bnr2.jpg';


const teamBlog = [
	{image: team1, image2: team5, name:"Martti Payne", title:"CEO, Chairman of the Board", },
  {image: team1, image2: team6, name:"Blair Hurry", title:"Operations Manager", },
	{image: team2, image2: team5, name:"Freddy Ordine", title:"Studio Coordinator", },
	{image: team3, image2: team5, name:"Kevin Hicklin", title:"Talent Acquisition", },
	{image: team4, image2: team5, name:"Ritchie Hume", title:"Sales Coordinator", },
  {image: team1, image2: team5, name:"Raj Patel", title:"Senior Developer", },
	{image: team2, image2: team6, name:"Sara Lee", title:"Magento Lead", },
	{image: team4, image2: team5, name:"Jonathan Crouch", title:"Full-Stack Developer", },
	{image: team2, image2: team5, name:"Lisa Masters", title:"Graphic Design, DTG Coordinator", },
	{image: team4, image2: team6, name:"Cara Henson", title:"Customer Support", },
];

class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='About Us' activeMenu='About Us' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">About Us</h4>
                                    <h2 className="box-title m-tb0">We create brand new corporate identities<span className="bg-primary"></span></h2>
                                    <p>At Continental Tech, we are innovators striving to keep at the forefront of technology. From eCommerce solutions to marketing sites and consulting, Continental Tech can cover a variety of needs for any client. </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">Our Mission<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">We create unique experiences</h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>Continental Tech not only gets results, but we strive to provide an experience-based working relationship, meaning our results are always exactly what you want them to be. We'll work closely with you on your projects to ensure total functionality - and satisfaction</p>
                                                <p>Our developers and fulfillment team are always ready to take on a new challenge. We're here and excited to push your venture to the next level - regardless of where you're at on your journey.</p>
                                                <p className="m-b0">Our cutting-edge technology and talented team will set you up right, and our knowledge and experience will keep you on your feet for years to come.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="section-full text-center bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2>Meet Our Team</h2>
                                </div>
                                <div className="row">
																	{teamBlog.map((item,index)=>(
																		<div className="col-lg-3 col-md-6 col-sm-6 m-b5" key={index}>
																			<div className="dlab-box">
																				<div className="dlab-media radius-sm"> <img src={item.image2} alt="" />

																				</div>
																				<div className="dlab-title-bx p-a10">
																					<h5 className="text-black m-a0">{item.name}</h5>
																					<span className="clearfix">{item.title}</span>
																				</div>
																			</div>
																		</div>
																	))}
                                </div>
                            </div>
                        </div>

                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span></h2>
						<p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mision<span className="bg-primary"></span></h3>
								<p className="font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
								<p className="font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
								<p className="font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
								<p className="font-16">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 	make a type specimen book.
								</p>
								<p className="font-16">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
								</p>
								<p className="font-16">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the	 industry's standard dummy text ever since the.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export {VisionBlog};
export default Aboutus1;
